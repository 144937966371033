<template>
  <div class="about">
    <h1>This is an about page</h1>
    <h1>isInit:{{Vue3GoogleOauth.isInit}}</h1>
    <h1>isAuthorized:{{Vue3GoogleOauth.isAuthorized}}</h1>
    <button v-on:click="SigInL">signIN</button>
    <button :disabled="!Vue3GoogleOauth.isAuthorized">signOut</button>

    {{user}}
  </div>
</template>
<script>
import { inject } from 'vue'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      user: ''
    }
  },
  methods: {
    async SigInL () {
      try {
        alert('ndndnd')
        const gg = await this.$gAuth.signIn()
        // console.log(this.$gAuth.signIn)
        if (!gg) {
          return null
        }
        this.user = gg.getBasicProfile().getEmail()
      } catch (error) {
        console.log(error)
        return null
      }
    }
  },
  setup () {
    const Vue3GoogleOauth = inject('Vue3GoogleOauth')
    return {
      Vue3GoogleOauth
    }
  }
}
</script>
